import AppContainer from './components/AppContainer'

export default function App () {
  return (
    <AppContainer>
      <div className='max-w-3xl mx-auto px-4 md:px-0 my-24 text-center'>
        <img
          src='ulises_gascon.jpg'
          alt='Foto de Ulises Gascon'
          width='400px'
          height='400px'
          className='rounded-lg mx-auto border-8 border-double'
        />
        <div className='my-8 mb-8' lang='en'>
          <h1 className='font-bold text-3xl md:text-5xl tracking-tight mb-1'>
            Ulises Gascón (He/him)
          </h1>
        </div>
        <div lang='en'>
          <p className='text-left'>
            Ulises Gascón is a member of <a className='link-underline' href='https://expressjs.com/en/resources/community.html' target='_blank' rel='noreferrer'><span className='font-bold'>the Express Technical Committee (TC)</span></a>, a <a className='link-underline' href='https://github.com/nodejs/node?tab=readme-ov-file#collaborators' target='_blank' rel='noreferrer'><span className='font-bold'>Node.js core collaborator</span></a> and <a className='link-underline' href='https://github.com/nodejs/node?tab=readme-ov-file#release-keys' target='_blank' rel='noreferrer'><span className='font-bold'>releaser</span></a>, and a <a className='link-underline' href='https://github.com/openjs-foundation/standards/pull/305' target='_blank' rel='noreferrer'><span className='font-bold'>TC39 delegate</span></a>. With over 10 years of experience as a software engineer, he has worked for prominent companies such as Google and IBM, as well as various consultancy firms and start-ups. Additionally, he is a leading contributor to numerous open-source organizations and projects, <span className='font-bold'>with several packages ranking in the <a className='link-underline' href='https://www.npmjs.com/~ulisesgascon' target='_blank' rel='noreferrer'>top 25 on the npm registry</a></span>.
          </p>
          <p className='mt-5 text-left'>
            He has earned recognition as a <a className='link-underline' href='https://www.docker.com/captains/ulises-gascon/' target='_blank' rel='noreferrer'><span className='font-bold'>Docker Captain</span></a>, <a className='link-underline' href='https://mvp.microsoft.com/en-US/mvp/profile/9c7e1f4f-0cce-ed11-a7c6-000d3a5603d3' target='_blank' rel='noreferrer'><span className='font-bold'>Microsoft Most Valuable Professional (MVP)</span></a>, and <a className='link-underline' href='https://developers.google.com/profile/u/ulisesgascon' target='_blank' rel='noreferrer'><span className='font-bold'>Google Developer Expert (GDE)</span></a> for his noteworthy contributions to the Node.js community. With extensive experience, he specializes in building IoT solutions with open hardware, developing SaaS products, creating developer tools, managing microservices, migrating legacy systems, and designing distributed systems
          </p>
        </div>
        <div className='mt-10 mb-5'>
          <h2 className='text-2xl text-gray-600 dark:text-gray-200 my-8'>Last Published Books</h2>
          <div className='flex flex-row justify-center gap-4 md:gap-8'>

            <a href='https://amzn.to/3YfSRi4' target='_blank' rel='noreferrer'>
              <img
                style={{ maxHeight: '600px' }}
                className='mx-auto border border-solid border-gray-300'
                src='el_gran_libro_de_nodejs_cover.png'
                alt='El gran libro de Node.js ("The Great Node.js book") by Ulises Gascón. Spanish translation of "Node.js for Beginners"'
              />
            </a>
            <a href='https://www.amazon.com/dp/1803245174' target='_blank' rel='noreferrer'>
              <img
                style={{ maxHeight: '600px' }}
                className='mx-auto'
                src='nodejs_for_beginners_book_cover.jpg'
                alt='It is the book cover "Node.js for Beginners by Ulises Gascón. A Comprehensive guide to building efficient, full-featured web applications with Node.js"'
              />
            </a>

          </div>
        </div>
        <div lang='en'>
          <h2 className='text-2xl text-gray-600 dark:text-gray-200 my-8'>Standards/Communities I Contribute To</h2>
          <ul className='list-none text-left px-10 text-lg'>
            <li>♦ <a className='link-underline' href='https://github.com/openjs-foundation/standards/pull/305' target='_blank' rel='noreferrer'>TC39 Delegate (OpenJS Foundation) since 2024</a></li>
            <li>♦ Participant of <a className='link-underline' href='https://github.com/tc39/tg3' target='_blank' rel='noreferrer'>the TC39 TG3 (Security WG)</a> since 2024</li>
            <li>♦ <a className='link-underline' href='https://github.com/nodejs/node/pull/51991' target='_blank' rel='noreferrer'>Node.js Core Collaborator</a> since 2024</li>
            <li>♦ Member of <a className='link-underline' href='https://github.com/openjs-foundation/security-collab-space/pull/202' target='_blank' rel='noreferrer'>the OpenJS Security Collaboration Space</a> since 2024</li>
            <li>♦ Regular Member of <a className='link-underline' href='https://github.com/openjs-foundation/cross-project-council/pull/1257' target='_blank' rel='noreferrer'>the OpenJS Cross Project Council</a> since 2024</li>
            <li>♦ Member of <a className='link-underline' href='https://github.com/expressjs/expressjs.com/pull/1460' target='_blank' rel='noreferrer'>Express.js technical Committee</a> since 2024</li>
            <li>♦ <a className='link-underline' href='https://speaking.ulisesgascon.com/nodejs-releases-working-group' target='_blank' rel='noreferrer'>Node.js Releaser</a> since 2023</li>
            <li>♦ Member of <a className='link-underline' href='https://speaking.ulisesgascon.com/nodejs-performance-team' target='_blank' rel='noreferrer'>the Node.js Performance Team</a> since 2022</li>
            <li>♦ Member of <a className='link-underline' href='https://speaking.ulisesgascon.com/nodejs-build-team' target='_blank' rel='noreferrer'>the Node.js Build Team</a> since 2022</li>
            <li>♦ Participant of <a className='link-underline' href='https://speaking.ulisesgascon.com/tc39-js-outreach-groups' target='_blank' rel='noreferrer'>the TC39 JS outreach groups</a> since 2022</li>
            <li>♦ Member of <a className='link-underline' href='https://speaking.ulisesgascon.com/nodejs-security-working-group' target='_blank' rel='noreferrer'>the Node.js Security WG</a> since 2022</li>
            <li>♦ Member of <a className='link-underline' href='https://owasp.org/' target='_blank' rel='noreferrer'>OWASP (The Open Web Application Security Project)</a> since 2022</li>
            <li>♦ Member of <a className='link-underline' href='https://github.com/expressjs/express/pull/4175' target='_blank' rel='noreferrer'>the Express.js Triage Team</a> since 2020</li>
            <li>♦ Member of <a className='link-underline' href='https://github.com/yeoman/yeoman.io/pull/793' target='_blank' rel='noreferrer'>the Yeoman Core Team</a> since 2019</li>
          </ul>
        </div>
        <div lang='en'>
          <h2 className='text-2xl text-gray-600 dark:text-gray-200 my-8'>Remarkable accomplishments</h2>
          <ul className='list-none text-left px-10 text-lg'>
            <li>► <a className='link-underline' href='https://openjsf.org/blog/2024-javascriptlandia-community-award-categories-a' target='_blank' rel='noreferrer'>JavaScriptLandia Awards Leading by Example in 2024</a></li>
            <li>► <a className='link-underline' href='https://snyk.io/snyk-ambassadors/' target='_blank' rel='noreferrer'>Snyk Ambassador</a> since 2023</li>
            <li>► <a className='link-underline' href='https://www.docker.com/captains/ulises-gascon/' target='_blank' rel='noreferrer'>Docker Captain</a> since 2023</li>
            <li>► <a className='link-underline' href='https://mvp.microsoft.com/en-us/PublicProfile/5005253' target='_blank' rel='noreferrer'>Most Valuable Professional (MVP) for Developer Technologies</a> since 2023</li>
            <li>► <a className='link-underline' href='https://owasp.org/' target='_blank' rel='noreferrer'>OWASP (The Open Web Application Security Project) Member</a> since 2022</li>
            <li>► <a className='link-underline' href='https://auth0.com/ambassador-program/ambassadors' target='_blank' rel='noreferrer'>Auth0 Ambassador</a> since 2020</li>
            <li>► <a className='link-underline' href='https://developers.google.com/community/experts/directory/profile/profile-ulises-gascon' target='_blank' rel='noreferrer'>Google Developer Expert (GDE) for Firebase</a> since 2019</li>
            <li>► Residency Advisor at <a className='link-underline' href='https://www.campus.co/' target='_blank' rel='noreferrer'>Google Campus</a> since 2017</li>
          </ul>
        </div>
        <div lang='en'>
          <h2 className='text-2xl text-gray-600 dark:text-gray-200 my-8'>Published Author</h2>
          <ul className='list-none text-left px-10 text-lg'>
            <li lang='es'>✎ <a className='link-underline' href='https://www.marcombo.com/libro/libros-tecnicos-de-arte-y-cientificos/informatica-libros-tecnicos-y-cientificos/el-gran-libro-de-node-js/' target='_blank' rel='noreferrer'>El Gran Libro de Node.js</a>. Marcombo (2024)</li>
            <li>✎ <a className='link-underline' href='https://www.amazon.com/dp/1803245174' target='_blank' rel='noreferrer'>Node.js for Beginners</a>. Packt (2024)</li>
            <li>✎ <a className='link-underline' href='https://dockerseguro.ulisesgascon.com' target='_blank' rel='noreferrer'>Docker Seguro</a>. Leanpub (2022)</li>
            <li>✎ <a className='link-underline' href='https://github.com/guidesmiths/cybersecurity-handbook' target='_blank' rel='noreferrer'>Cybersecurity handbook</a>. Guidesmiths (2020)</li>
            <li>✎ <a className='link-underline' href='https://javascriptinspirate.ulisesgascon.com' target='_blank' rel='noreferrer'>JavaScript, ¡Inspírate!</a>. Leanpub (2017)</li>
          </ul>
        </div>
        <h2 className='text-2xl text-gray-600 dark:text-gray-200 my-8'>Awards</h2>
        <div className='flex flex-row flex-wrap justify-center'>
          <a href='https://mvp.microsoft.com/en-us/PublicProfile/5005253' target='_blank' rel='noreferrer'>
            <img
              style={{ maxHeight: '210px' }}
              src='MVP_Blue_Badge_min.png'
              alt='Microsoft Most Valuable Professional (MVP) badge'
              className='px-5 my-8'
            />
          </a>

          <a href='https://www.docker.com/captains/ulises-gascon/' target='_blank' rel='noreferrer'>
            <img
              style={{ maxHeight: '210px', border: '1px solid #DDE6ED' }}
              src='docker_captain_badge_min.png'
              alt='Docker Captain badge'
              className='px-5 my-8'
            />
          </a>

          <a href='https://developers.google.com/community/experts/directory/profile/profile-ulises-gascon' target='_blank' rel='noreferrer'>
            <img
              src='GDE-Firebase-Badge_min.png'
              alt='GDE Firebase badge (since 2019)'
              className='px-5 my-8'
            />
          </a>
          <a href='https://auth0.com/ambassador-program/ambassadors#Ulises%20Gasc%C3%B3n' target='_blank' rel='noreferrer'>
            <img
              src='auth0-ambassador_min.png'
              alt='Auth0 Ambassador badge (since 2020)'
              className='px-5 my-8'
            />
          </a>
          <a href='https://snyk.io/snyk-ambassadors/' target='_blank' rel='noreferrer'>
            <img
              style={{ maxHeight: '200px' }}
              src='snyk_ambassador.png'
              alt='Snyk Ambassador badge'
              className='px-5 my-8'
            />
          </a>
        </div>
      </div>
    </AppContainer>
  )
}
