import React from 'react'

import ThemeToggle from './ThemeToggle'

export default function Header () {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  return (
    <header className='max-w-4xl mx-auto px-4'>
      <div className='flex items-center justify-between py-8'>
        <div>
          <a href='/'>
            <div className='relative w-10 h-10 cursor-pointer'>
              <img
                src='ulises_gascon.jpg'
                alt='Foto de Ulises Gascon'
                width='40px'
                height='40px'
                  // objectFit='cover'
                className='rounded-full'
              />
            </div>
          </a>
        </div>
        <nav className='flex items-center'>
          <div className='flex items-center justify-end flex-row space-x-8'>
            {[
              { title: 'Proyectos', href: 'https://opensource.ulisesgascon.com' },
              { title: 'Blog', href: 'https://blog.ulisesgascon.com' },
              { title: 'Charlas', href: 'https://speaking.ulisesgascon.com' }
            ].map((nav) => (
              <a href={nav.href} key={nav.title} className='font-semibold text-base md:text-lg link-underline'>
                {nav.title}
              </a>
            ))}
            <ThemeToggle />
          </div>
        </nav>
      </div>
    </header>
  )
}
