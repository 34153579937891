import { FaGithub, FaLinkedin, FaTwitter, FaYoutube, FaHeart, FaMastodon } from 'react-icons/fa'
import Emoji from './Emoji'
export default function Footer () {
  return (
    <>
      <hr className='mt-10' />
      <footer>
        <div className='flex flex-col justify-around items-center md:flex-row md:items-baseline md:justify-between max-w-4xl px-4 mx-auto py-6'>
          <div className='prose prose-indigo dark:prose-dark'>
            Creado con {' '}
            <a href='https://es.reactjs.org/'>React</a>
            {' '} y mucho  {' '}
            <Emoji emoji='💖' label='Corazón brillante' />  {' '}
            <a href='https://thanks.ulisesgascon.com/'>Open Source</a>
            .
          </div>
          <div className='flex items-center justify-end pt-8 md:pt-0 space-x-10'>
            <a href='https://github.com/ulisesgascon' className='social-link' aria-label='Github'>
              <FaGithub size='1.3em' />
            </a>
            <a href='https://www.twitter.com/kom_256/' className='social-link' aria-label='Twitter'>
              <FaTwitter size='1.3em' />
            </a>
            <a href='https://www.linkedin.com/in/ulisesgascon/' className='social-link' aria-label='Linkedin'>
              <FaLinkedin size='1.3em' />
            </a>
            <a rel='me' href='https://fosstodon.org/@ulisesgascon' className='social-link' aria-label='@ulisesgascon@fosstodon.org'>
              <FaMastodon size='1.3em' />
            </a>
            <a href='https://www.youtube.com/channel/UC87WsLcLxxgV9ZJjoWf1pQw/' className='social-link' aria-label='Youtube'>
              <FaYoutube size='1.3em' />
            </a>
            <a href='https://github.com/sponsors/UlisesGascon' className='social-link' aria-label='Sponsor me on GitHub'>
              <FaHeart size='1.3em' />
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}
